@import './layout.scss';

:root {
  --app-max-width: 552px;

  --white: #fff;

  --black: #060201;
  --black-focus: rgba(0, 0, 0, 0.12);
  --black-hover: rgba(0, 0, 0, 0.04);
  --black-pressed: rgba(0, 0, 0, 0.1);
  --black-dragged: rgba(0, 0, 0, 0.08);
  --black-selected: rgba(0, 0, 0, 0.08);

  --iris: #7d00d4;
  --iris-hover: rgba(125, 0, 212, 0.04);
  --iris-lighter: #9e2feb;
  --iris-focus: #7d00d41f;
  --iris-pressed: #7d00d41a;
  --iris-dragged: #7d00d414;
  --iris-selected: #7d00d414;

  &[data-theme] {
    &[data-theme='black'] {
      --iris: var(--black);
      --iris-hover: var(--black-hover);
      --iris-focus: var(--black-focus);
      --iris-pressed: var(--black-pressed);
      --iris-dragged: var(--black-dragged);
      --iris-selected: var(--black-selected);
    }
  }

  --iris-light: rgba(125, 0, 212, 0.08);
  --iris-light-pressed: rgba(125, 0, 212, 0.1);
  --iris-light-selected: rgba(125, 0, 212, 0.08);
  --iris-light-focus: rgba(125, 0, 212, 0.12);
  --iris-light-dragged: rgba(125, 0, 212, 0.08);
  --iris-light-text: #7d00d4;

  --onSurfaceColors-highEmphasis: #1a1c23;
  --onSurfaceColors-mediumEmphasis: #616475;
  --onSurfaceColors-disabled: #9b9eab;

  --dim: #ebecf2;
  --disabled-button: #f3f3f3;

  --destructive: #fcedec;
  --destructive-hover: #fef7f6;
  --destructive-focus: #fbe4e3;
  --destructive-pressed: #fce8e8;
  --destructive-text: #df1d17;

  --pending: #1995fd;
  --pending-light: rgba(65, 142, 229, 0.2);

  --onColorColors-highEmphasis: #f5f5f5;
  --onColorColors-mediumEmphasis: rgba(255, 255, 255, 0.74);
  --onColorColors-disabled: rgba(255, 255, 255, 0.38);

  --danger: #df1d17;
  --danger-transparent: #df1d171a;

  --success: #22a958;
  --success-transparent: #22a9581a;

  --validation-color: #7d00d4;
  --validation-failed-color: #df1d17;

  --loading-g-one: var(--iris);
  --loading-g-two: #5b009a;
  --loading-g-three: #40006c;

  &[data-theme] {
    &[data-theme='black'] {
      --loading-g-two: var(--iris);
      --loading-g-three: var(--iris);
    }
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-image: url('/images/vectors/pattern.svg');
  background-color: var(--iris) !important;
  background-attachment: fixed;
  position: fixed;
  inset: 0;
}

a {
  color: var(--onSurfaceColors-highEmphasis);
}
