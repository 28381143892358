.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.row-center {
  @extend .row;
  justify-content: center;
  align-items: center;
}

.row-start {
  @extend .row;
  justify-content: flex-start;
  align-items: flex-start;
}

.row-end {
  @extend .row;
  justify-content: flex-end;
  align-items: flex-end;
}

.row-between {
  @extend .row;
  justify-content: space-between;
}

.row-around {
  @extend .row;
  justify-content: space-around;
}

.row-even {
  @extend .row;
  justify-content: space-evenly;
}

.row-wrap {
  @extend .row;
  flex-wrap: wrap;
}

.row-wrap-reverse {
  @extend .row;
  flex-wrap: wrap-reverse;
}

.col-center {
  @extend .col;
  justify-content: center;
  align-items: center;
}

.col-start {
  @extend .col;
  justify-content: flex-start;
  align-items: flex-start;
}

.col-end {
  @extend .col;
  justify-content: flex-end;
  align-items: flex-end;
}

.col-between {
  @extend .col;
  justify-content: space-between;
}

.col-around {
  @extend .col;
  justify-content: space-around;
}

.col-even {
  @extend .col;
  justify-content: space-evenly;
}

.col-wrap {
  @extend .col;
  flex-wrap: wrap;
}

.col-wrap-reverse {
  @extend .col;
  flex-wrap: wrap-reverse;
}

.expand {
  width: 100%;
  height: 100%;
}

@mixin square($size) {
  width: $size;
  height: $size;
}
